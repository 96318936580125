import { FC } from "react"
import styled from "@emotion/styled"
import {
  showCookiePreferences,
} from "@heyhabito/cookie-popup"

import {
  HabitoLogo,
  colours,
  horizontal,
  resets,
  vertical,
  TertiaryTwoLink,
  TertiaryTwoCTA,
} from "design-kit"

const List = styled.ul`
  ${resets.list};

  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 ${horizontal.s};
`

const Footer = styled.footer`
  position: sticky;
  top: calc(100vh - 96px);
  background-color: ${colours.greyScale.grey25};
  color: ${colours.offBlack};
  padding: ${vertical.xs} ${vertical.xxl};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: ${horizontal.xxs} ${horizontal.m};
`

export const MinimalFooter: FC = () => (
  <Footer>
    <HabitoLogo height={20} />
    <List>
      <li>
        <TertiaryTwoLink target="_blank" text="Terms" href="/terms" />
      </li>
      <li>
        <TertiaryTwoLink target="_blank" text="Privacy" href="/privacy" />
      </li>
      <li>
        <TertiaryTwoCTA
          text="Cookie settings"
          onClick={showCookiePreferences}
        />
      </li>
      <li>
        <TertiaryTwoLink target="_blank" text="Cookie policy" href="/cookies" />
      </li>
      <li>
        <TertiaryTwoLink
          target="_blank"
          text="Accessibility"
          href="https://help.habito.com/en/articles/3224076-about-accessibility"
        />
      </li>
      <li>
        <TertiaryTwoLink
          target="_blank"
          text="Help centre"
          href="https://help.habito.com"
        />
      </li>
    </List>
  </Footer>
)
