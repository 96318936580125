import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import {
  BodyBold,
  BodySmall,
  GridRow,
  HabitoLogo,
  MetaText,
  breakpoints,
  colours,
  column,
  horizontal,
  mixins,
  resets,
  typographyStyles,
  vertical,
  TrustpilotLogo,
  TertiaryTwoCTA,
  TertiaryTwoLink,
} from "design-kit"

import {
  Link,
  aboutHabitoLinks,
  mkAboutHabitoForIntermediariesLinks,
  resourcesLinks,
  forCustomersLinks,
  contactLinks,
  socialLinks,
} from "./links"

import {
  showCookiePreferences,
} from "@heyhabito/cookie-popup"

import logo_bba from "./assets/logo_bba.svg"

const FooterContainer = styled.footer`
  background-color: ${colours.greyScale.grey25};
  color: ${colours.offBlack};
  padding: ${vertical.m} 0;
`

const LinkWithFocus = styled.a`
  display: inline-block;

  &,
  &:visited {
    color: ${colours.offBlack};
    text-decoration: none;
  }

  &:focus {
    ${mixins.focused};
  }
`

export interface TrustpilotData {
  stars: 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4 | 4.5 | 5
  description: string
  numberOfReviewsFormatted: string
}

const InfoSection: React.FC<{ trustpilot?: TrustpilotData }> = ({
  trustpilot,
}) => (
  <div
    css={css`
      ${column({
        widthMobile: 4,
        widthTablet: 6,
        widthDesktop: 5,
      })}

      ${breakpoints.desktop`
        order: 1;
      `}
    `}
  >
    <div
      css={css`
        margin-bottom: ${vertical.m};
      `}
    >
      <LinkWithFocus href="/">
        <HabitoLogo height={40} />
        <BodyBold
          css={css`
            margin: ${vertical.xxs} 0 0 0;
          `}
        >
          Mortgages made easier
        </BodyBold>
      </LinkWithFocus>
    </div>

    {trustpilot && (
      <div
        css={css`
          margin-bottom: ${vertical.m};
        `}
      >
        <LinkWithFocus
          href="https://uk.trustpilot.com/review/habito.com"
          rel="noopener noreferrer"
        >
          <TrustpilotLogo
            width={250}
            tint={colours.offBlack}
            stars={trustpilot.stars}
          />
          <BodySmall
            css={css`
              margin: ${vertical.xxs} 0 0 0;
            `}
          >
            Rated {trustpilot.description} based on over{" "}
            {trustpilot.numberOfReviewsFormatted} customer reviews
          </BodySmall>
        </LinkWithFocus>
      </div>
    )}

    <MetaText
      css={css`
        text-transform: none;
        margin: 0 0 ${vertical.xs} 0;
      `}
    >
      Hey Habito Ltd. is registered in England and Wales (09384953) with address
      C/O 4th Floor Phoenix House, 1 Station Hill, Reading, Berkshire, RG1 1NB,
      is authorised and regulated by the Financial Conduct Authority and is on
      the Financial Services Register (714187).
    </MetaText>

    <MetaText
      css={css`
        text-transform: none;
        margin: 0 0 ${vertical.m} 0;
      `}
    >
      Your home may be repossessed if you do not keep up repayments on your
      mortgage.
    </MetaText>

    <div
      css={css`
        display: flex;
        flex-direction: row;
      `}
    >
      <img
        src={logo_bba}
        alt="British Bank Awards 2020: Best Mortgage Broker"
        css={css`
          margin-right: ${horizontal.m};
          max-width: 100%;
        `}
      />
    </div>
  </div>
)

const LinkListUL = styled.ul`
  ${resets.list};

  & + & {
    margin-top: ${vertical.l};
  }
`

const LinkListItemLI = styled.li`
  margin: 0;
`

const LinkListItem: React.FC<{ link: Link }> = ({ link }) => (
  <LinkListItemLI>
    <TertiaryTwoLink
      text={link.text}
      href={link.url}
      rel={link.directing === "internal" ? undefined : "noopener noreferrer"}
      size="small"
      width="content-width"
    />
  </LinkListItemLI>
)

const LinkListItems: React.FC<{ links: Link[] }> = ({ links }) => (
  <React.Fragment>
    {links.map((link, i) => (
      <LinkListItem key={i} link={link} />
    ))}
  </React.Fragment>
)

const LinkList: React.FC<{ links: Link[] }> = ({ links }) => (
  <LinkListUL>
    <LinkListItems links={links} />
  </LinkListUL>
)

const LinkSection = styled.div`
  margin-top: ${vertical.m};
  flex: 1 1 50%;

  ${breakpoints.tablet`
    flex-basis: 25%;
  `}

  ${breakpoints.desktop`
    margin-top: 0;
  `}
`

const LinkSectionHeading = styled.h3`
  ${typographyStyles.metaText};
  margin: 0 0 ${vertical.s} 0;
`

const LinksContainer: React.FC<{ aboutLinks: Link[] }> = ({ aboutLinks }) => (
  <div
    css={css`
      ${column({
        widthMobile: 4,
        widthTablet: 6,
        widthDesktop: 7,
      })}

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      ${breakpoints.desktop`
        order: 0;
      `}
    `}
  >
    <h2
      css={css`
        ${mixins.invisible};
      `}
    >
      Useful links
    </h2>

    <LinkSection>
      <LinkSectionHeading>About Habito</LinkSectionHeading>
      <LinkListUL>
        <LinkListItems links={aboutLinks} />
        <LinkListItemLI>
          <TertiaryTwoCTA
            text="Cookie settings"
            width="content-width"
            size="small"
            onClick={showCookiePreferences}
          />
        </LinkListItemLI>
      </LinkListUL>
    </LinkSection>

    <LinkSection>
      <LinkSectionHeading>Resources</LinkSectionHeading>
      <LinkList links={resourcesLinks} />
    </LinkSection>

    <LinkSection>
      <LinkSectionHeading>For customers</LinkSectionHeading>
      <LinkList links={forCustomersLinks} />
    </LinkSection>

    <LinkSection>
      <LinkSectionHeading>Contact</LinkSectionHeading>
      <LinkList links={contactLinks} />
      <LinkList links={socialLinks} />
    </LinkSection>
  </div>
)

export const Footer: React.FC<{ trustpilot?: TrustpilotData }> = ({
  trustpilot,
}) => (
  <FooterContainer>
    <GridRow>
      <InfoSection trustpilot={trustpilot} />
      <LinksContainer aboutLinks={aboutHabitoLinks} />
    </GridRow>
  </FooterContainer>
)

export default Footer

export const IntermediaryFooter: React.FC<{
  intermediariesPrivacyPolicyURL: string
  termsOfBusinessURL: string
}> = ({ intermediariesPrivacyPolicyURL, termsOfBusinessURL }) => (
  <FooterContainer>
    <GridRow>
      <InfoSection />
      <LinksContainer
        aboutLinks={mkAboutHabitoForIntermediariesLinks(
          intermediariesPrivacyPolicyURL,
          termsOfBusinessURL
        )}
      />
    </GridRow>
  </FooterContainer>
)

export { MinimalFooter } from "./minimal"
