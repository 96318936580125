export interface Link {
  text: string
  url: string
  directing: "internal" | "external"
}

export const aboutHabitoLinks: Link[] = [
  { text: "About us", url: "/about-us", directing: "internal" },
  { text: "FAQs", url: "https://help.habito.com", directing: "external" },
  { text: "Lenders", url: "/lenders", directing: "internal" },
  { text: "Terms of use", url: "/terms", directing: "internal" },
  { text: "Privacy policy", url: "/privacy", directing: "internal" },
  { text: "Cookie policy", url: "/cookies", directing: "internal" },
]

export function mkAboutHabitoForIntermediariesLinks(
  intermediariesPrivacyPolicyURL: string,
  termsOfBusinessURL: string
): Link[] {
  return [
    { text: "About us", url: "/about-us", directing: "internal" },
    { text: "FAQs", url: "https://help.habito.com", directing: "external" },
    { text: "Lenders", url: "/lenders", directing: "internal" },
    { text: "Terms of use", url: "/terms", directing: "internal" },
    {
      text: "Terms of business",
      url: termsOfBusinessURL,
      directing: "external",
    },
    { text: "General Privacy policy", url: "/privacy", directing: "internal" },
    {
      text: "Intermediaries Privacy policy",
      url: intermediariesPrivacyPolicyURL,
      directing: "external",
    },
    { text: "Cookie policy", url: "/cookies", directing: "internal" },
  ]
}

export const resourcesLinks: Link[] = [
  {
    text: "Mortgage calculator",
    url: "/mortgage-calculator",
    directing: "internal",
  },
  {
    text: "Mortgage comparison",
    url: "/mortgage-comparison",
    directing: "internal",
  },
  {
    text: "Mortgage in principle",
    url: "/mortgage-in-principle",
    directing: "internal",
  },
  {
    text: "Energy efficiency report",
    url: "https://habito.greenval.co.uk",
    directing: "external",
  },
]

export const forCustomersLinks: Link[] = [
  {
    text: "Accessibility",
    url: "https://help.habito.com/en/articles/3224076-about-accessibility",
    directing: "external",
  },
  { text: "Log in", url: "/log-in", directing: "internal" },
  { text: "Habito Plus", url: "/home-buying", directing: "internal" },
]

export const contactLinks: Link[] = [
  { text: "Press", url: "/press", directing: "internal" },
  { text: "Careers", url: "/careers", directing: "internal" },
  {
    text: "Help centre",
    url: "https://help.habito.com",
    directing: "external",
  },
]

export const socialLinks: Link[] = [
  { text: "Twitter", url: "https://twitter.com/habito", directing: "external" },
  {
    text: "Facebook",
    url: "https://www.facebook.com/heyhabito",
    directing: "external",
  },
  {
    text: "Instagram",
    url: "https://www.instagram.com/habitomortgages",
    directing: "external",
  },
  {
    text: "LinkedIn",
    url: "https://www.linkedin.com/company/habito-",
    directing: "external",
  },
]
